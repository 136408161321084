import ButtonLoader, {
  ErrorLoader,
} from "../../../../../../UI/Loaders/Loaders";
import { formatMoney } from "../../../../../../helpers/formatter";
import { IDisbursementSummary } from "../../interface";
import { Space, Switch, Button } from "antd";
import styles from "./DisbursementSummary.module.scss";
import closeCircle from "../../../../../../assets/icons/fontawesome/svgs/regular/close-circle.svg";
import successTick from "../../../../../../assets/icons/fontawesome/svgs/regular/tick-circle.svg";
import { Toaster } from "react-hot-toast";
import LoanOfferModal from "../../../../LoanOffer/LoanOffer";
import { useState } from "react";
import Swal from "sweetalert2";
import toast from "react-hot-toast";

const DisbursementSummary = ({
  first_name,
  last_name,
  state,
  loan_amount,
  loan_id,
  handleOptionSelect,
  disburseLoan,
  handleGenerateLoanSchedule,
  handleSaveLoanDetails,
  onCloseModal,
  loanScheduleResponse,
  saveLoanDetailsResponse,
  funderOptions,
  switchState,
  setSwitchState,
  sendNewLoanOffer,
  closeNewLoanOfferTrigger,
  triggerNewLoanOffer,
  loanOfferState,
  loan_offers,
}: IDisbursementSummary) => {
  const [showLoanOffer, setShowLoanOffer] = useState(false);
  const handleSwitchChange = (checked: any) => {
    setSwitchState(checked);
  };

  const handleSendNewOffer = () => {
    if (!state.comment) {
      toast.error("Please add a comment!");
    } else {
      Swal.fire({
        icon: "warning",
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        showCancelButton: true,
        confirmButtonText: "Yes, send it!",
        cancelButtonText: "No, cancel!",
      }).then((result) => {
        if (result.isConfirmed) {
          sendNewLoanOffer();
        }
      });
    }
  };

  return (
    <div className={styles.disbursement_container}>
      {loan_offers?.length > 0 &&
        loan_offers?.some((offer) => offer.status === "Pending") && (
          <div className={styles.awaiting_customer_loan_offer_acceptance}>
            <span className={styles.awaiting_customer_loan_offer_text}>
              Awaiting customer new offer acceptance
            </span>
          </div>
        )}
      <h3 className="Modal__title p-b-1">Disbursement Summary</h3>
      {/* 2. Confirm Update Role Modal*/}
      <div id="disbursement-confirm">
        <div className="m-b-30">
          <header className={styles.disbursement_summary_header}>
            <div>
              <h5>Are you sure you want to disburse the following loan? </h5>
              <p>Borrower Name: {`${first_name} ${last_name}`}</p>
              <p>Loan Amount: {formatMoney(loan_amount)}</p>
            </div>
            <Button
              className={styles.view_offer_btn}
              onClick={() => setShowLoanOffer(true)}
            >
              View Offer
            </Button>
          </header>
          <form>
            {state?.error && (
              <p className="text-danger">Field cannot be blank</p>
            )}
            <div className="disburseSummary">
              <label style={{ width: "100%" }}>
                Approved Amount <span style={{ color: "red" }}>*</span>
                <input
                  className="form-control"
                  name="amountApproved"
                  value={state?.amountApproved}
                  onChange={(e) => handleOptionSelect(e)}
                />
              </label>

              <label style={{ width: "100%" }}>
                Approved Tenor <span style={{ color: "red" }}>*</span>
                <input
                  className="form-control"
                  name="approvedTenor"
                  value={state?.approvedTenor}
                  onChange={(e) => handleOptionSelect(e)}
                />
              </label>

              <label style={{ width: "100%" }}>
                Effective Date <span style={{ color: "red" }}>*</span>
                <input
                  className="round user__form form-control"
                  name="selectedDate"
                  type="date"
                  id="selectedDate"
                  value={state?.selectedDate}
                  onChange={(e) => handleOptionSelect(e)}
                />
              </label>

              <label style={{ width: "100%" }}>
                First Repayment Date
                <input
                  className="round user__form form-control"
                  name="firstRepaymentDate"
                  type="date"
                  id="firstRepaymentDate"
                  value={
                    state?.firstRepaymentDate ? state?.firstRepaymentDate : ""
                  }
                  onChange={(e) => handleOptionSelect(e)}
                />
              </label>
              <label style={{ width: "100%" }}>
                Funder
                <select
                  className="round user__form form-control"
                  name="selectedFunder"
                  id="selectedFunder"
                  value={state?.selectedFunder}
                  onChange={(e) => handleOptionSelect(e)}
                >
                  <option selected></option>
                  {funderOptions}
                </select>
              </label>

              <label style={{ width: "100%" }}>
                Comment
                <input
                  className="form-control"
                  name="comment"
                  value={state?.comment}
                  onChange={(e) => handleOptionSelect(e)}
                />
              </label>
            </div>
            <div style={{ width: "100%", display: "flex" }}>
              <div>
                <label style={{ width: "100%", marginTop: "10px" }}>
                  Is Credit Bureau Check Done?
                </label>
                <Space direction="vertical">
                  <Switch
                    checkedChildren="Yes"
                    unCheckedChildren="No"
                    checked={switchState}
                    onChange={handleSwitchChange}
                  />
                </Space>
              </div>

              {triggerNewLoanOffer &&
              (loan_offers?.length === 0 ||
                loan_offers?.some((offer) => offer.status !== "Pending")) ? (
                <div className={styles.new_loan_offer_trigger_container}>
                  <img
                    src={closeCircle}
                    alt=""
                    className={styles.close_btn}
                    onClick={closeNewLoanOfferTrigger}
                  />
                  <span className={styles.new_offer_trigger_text}>
                    Would you like to send this as a loan offer to the user
                  </span>
                  <Button
                    className={styles.new_loan_offer_trigger_action_btn}
                    onClick={handleSendNewOffer}
                    loading={loanOfferState.isLoading}
                    disabled={
                      loanOfferState.isLoading ||
                      (loan_offers?.length > 0 &&
                        loan_offers?.some(
                          (offer) => offer.status === "Pending",
                        ))
                    }
                  >
                    {loanOfferState.isLoading ? "Processing..." : "Yes"}
                  </Button>
                </div>
              ) : null}

              {loanOfferState.success && (
                <div className={styles.new_loan_offer_success_msg_container}>
                  <img src={successTick} alt="" />
                  <span className={styles.new_offer_success_text}>
                    Loan offer has been sent to this user.
                  </span>
                </div>
              )}
            </div>
            <div className="Form__action-buttons m-t-2">
              <div>
                <button
                  data-id={loan_id}
                  onClick={(e) => handleSaveLoanDetails(e)}
                  disabled={
                    !(
                      state?.selectedDate &&
                      state?.amountApproved &&
                      state?.approvedTenor
                    )
                  }
                  className="btn zoom-element login-btn m-r-1"
                >
                  <i className="fa fa-save" /> Save
                  {saveLoanDetailsResponse?.status === "loading" && (
                    <ButtonLoader />
                  )}
                </button>

                <button
                  type="button"
                  data-id={loan_id}
                  onClick={handleGenerateLoanSchedule}
                  disabled={
                    !(
                      state?.selectedDate &&
                      state?.amountApproved &&
                      state?.approvedTenor
                    )
                  }
                  className="btn zoom-element btn login-btn m-r-1"
                >
                  <i className="fa fa-clock" /> Generate Loan Schedule
                  {loanScheduleResponse?.status === "loading" && (
                    <ButtonLoader />
                  )}
                </button>

                <button
                  type="submit"
                  data-id={loan_id}
                  onClick={disburseLoan}
                  disabled={
                    !(
                      state?.comment &&
                      state?.selectedFunder &&
                      state?.selectedDate &&
                      state?.amountApproved &&
                      state?.approvedTenor
                    ) ||
                    (loan_offers?.length > 0 &&
                      loan_offers?.some((offer) => offer.status === "Pending"))
                  }
                  className="btn zoom-element login-btn mr-0 mr-auto"
                >
                  <i className="fa fa-check-circle" /> Proceed
                </button>
              </div>

              <button
                type="button"
                onClick={onCloseModal}
                className="btn zoom-element btn login-btn m-0 ml-auto"
              >
                <i className="fa fa-times-circle" /> Cancel
              </button>
            </div>
          </form>
        </div>
      </div>

      {/* 2. Disbursement Loader*/}
      <div
        id="disbursement-loader"
        className="hidden loading-overlay speed-1x fadeInRight"
      >
        <div className=" form-loader form-loader-login verify">
          <div className="centerLoader">
            <div className="loading" id="adv-loader-alt" />
            <div className="center-txt webapp-ripple-text">
              <i className="notifier fas fa-user-cog" />
              Processing ...
            </div>
          </div>
        </div>
      </div>

      {/* 3. Disbursement Success Message*/}
      <div
        id="disbursement-success"
        className="hidden loading-overlay speed-1x fadeInRight"
      >
        <div className="form-loader form-loader-login verify">
          <div className="centerLoader">
            <div className="loading success-loader">
              <i className="webapp-ripple-notification success-text fa fa-check-circle" />
            </div>
            <div className="center-txt webapp-ripple-text">
              <i className="notifier far fa-check-circle" />
              Disbursement Initiated
            </div>
          </div>
        </div>
      </div>

      {/* 4. Disbursement Error */}
      <div
        id="disbursement-error"
        className="hidden loading-overlay speed-1x fadeInRight"
      >
        <ErrorLoader
          icon="fas fa-wifi"
          text="Network Issue"
          subText="Unable to connect to Advancly services."
        />
      </div>
      <Toaster position="top-center" reverseOrder={false} />
      {showLoanOffer && (
        <LoanOfferModal
          setShowLoanOffer={setShowLoanOffer}
          loan_offers={loan_offers}
        />
      )}
    </div>
  );
};

export default DisbursementSummary;
